<template>
  <cp-general-modal
    ref="modalLogic"
    :hide-footer="working"
    :ok-disabled="isOkButtonDisable"
    :ok-text="$t('signatures.cancelTransactionModal.button.sign.now')"
    :title="$t('signatures.cancelTransactionModal.title')"
    scrollable
    @onCancel="$emit('onCancel')"
    @onOk="submitTransactions"
    @shown="init"
  >
    <small class="mx-4 d-flex flex-row align-items-start">
      <i class="ion ion-ios-information-circle-outline mt-1 pr-1" />
      {{ $t("signatures.cancelTransactionModal.message.disclaimer") }}
    </small>
    <div class="text-center">
      <b-spinner
        v-if="isLoading"
        big
        class="align-middle"
      />
    </div>
    <div
      v-if="isMultiSigTransactionSelectedAndLoaded"
      class="alert alert-warning mx-4 mt-4"
    >
      {{ $t('signatures.signTransactionModal.message.multipleMultiSigTransactionsSelected') }}
    </div>
    <div
      v-if="!isLoading"
      class="card border-light mx-4 mt-4"
    >
      <div v-if="isSingleTransactionSelected">
        <b-card>
          <strong>{{ $t('signatures.field.id') }}</strong>{{ transactions[0].id }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.type') }}</strong>{{ transactions[0].type }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.description') }}</strong>
          <description :description-text="transactions[0].description" />
        </b-card>
        <b-card v-if="displayMultiSigWallets">
          <strong>{{ $t('signatures.field.signatureCount') }}</strong>
          <description :description-text="signatureCount" />
        </b-card>
      </div>
      <div v-else>
        <b-card>
          {{ $t('signatures.cancelTransactionModal.message.signTransactionsIds', [getTransactionsIds]) }}
        </b-card>
      </div>
    </div>
    <b-card
      v-if="!isLoading"
      class="border-light mx-4 mt-4"
    >
      <label class="form-label">
        {{ $t('signatures.signTransactionModal.select.signatureType.text') }}
      </label>
      <cp-select
        v-model="selectedSignatureType"
        :disabled="isMultiSigWalletSelectDisabled"
        :options="getSignatureTypes"
        name="signatureTypeSelect"
        @input="signatureTypeChanged"
      />
    </b-card>
    <b-card
      v-if="displayMultiSigWallets && !isLoading"
      class="border-light mx-4 mt-4"
    >
      <label class="form-label">
        {{ $t('signatures.signTransactionModal.select.multiSigWallet.text') }}
      </label>
      <cp-select
        v-model="selectedMultiSigWallet"
        :disabled="disableMultiSigWalletSelect"
        :options="multiSigWalletOptions"
        :placeholder="true"
        name="multiSignatureSelect"
        @input="multiSigChanged"
      />
    </b-card>

    <b-card
      v-if="!isLoading"
      class="border-light mx-4 mt-4"
    >
      <label class="form-label">
        {{ $t('signatures.signTransactionModal.signatureMethod.text') }}
      </label>
      <br>
      <b-form-radio
        v-model="selectedOptionToSign"
        :disabled="hasMultiSigWalletPendingSignatures"
        value="withPrivateKey"
        @input="optionToSignChanged()"
      >
        {{ $t('signatures.signTransactionModal.signatureMethod.method.walletAddressPrivateKey') }}
      </b-form-radio>

      <div class="card border-light ml-5">
        <b-card>
          <cp-input
            v-model="signerAddress"
            :disabled="!!signerAddress"
            :placeholder="$t('signatures.signTransactionModal.input.walletAddress.placeholder')"
            name="signerAddress"
          />
          <br>
          <cp-input
            v-model="privateKey"
            :disabled="isMultiSigTransactionWithPendingSignatures"
            :placeholder="$t('signatures.signTransactionModal.input.privateKey.placeholder')"
            :validate="getPrivateKeyInputValidation"
            name="privateKey"
          />
          <div class="pt-3">
            <small>
              <i class="ion ion-ios-information-circle-outline" />
              {{ $t('signatures.signTransactionModal.message.privateKeyCaution') }}
            </small>
          </div>
        </b-card>
      </div>
      <br>
      <b-form-radio
        v-model="selectedOptionToSign"
        :disabled="multipleMultiSigTransactionsSelected || hasMultiSigWalletPendingSignatures"
        value="withLedger"
      >
        {{ $t('signatures.signTransactionModal.signatureMethod.method.usbLedgerDevice') }}
      </b-form-radio>
      <br>
      <b-form-radio
        v-model="selectedOptionToSign"
        :disabled="multipleMultiSigTransactionsSelected || hasMultiSigWalletPendingSignatures"
        value="withSWIM"
      >
        {{ $t('signatures.signTransactionModal.signatureMethod.method.package') }}
      </b-form-radio>
      <br>
      <CPSwim
        v-if="isSWIMOptionSelected"
        :transactions="tList"
        @onSuccess="onSuccess"
      />
      <small v-show="displayLastSignMessage">
        <i class="ion ion-ios-information-circle-outline" />
        {{ $t('signatures.signTransactionModal.message.lastSignatureMessage') }}
      </small>
    </b-card>
    <div
      v-if="working"
      class="mx-4 mt-4"
    >
      <label>{{ $t('signatures.signTransactionModal.label.signingTransactions') }}</label>
      <b-progress
        v-if="working"
        :max="tList.length"
        class="my-3"
        show-value
      >
        <b-progress-bar
          :value="signed + 1"
          variant="primary"
        >
          {{ $t('signatures.signTransactionModal.label.signing', {signed: signed + 1, total: tList.length}) }}
        </b-progress-bar>
      </b-progress>
    </div>

    <div
      v-if="errors.length"
      class="card-body"
    >
      <cp-error-notices :errors="errors" />
    </div>
  </cp-general-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import async from 'async';

import { BlockchainTransactionSigner } from '@securitize/blockchain-transaction-signer';
import CpErrorNotices from '~/components/common/error-notices';
import Description from './description';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import { CpInput } from '~/components/common/standalone-components/inputs';
import { signatureTypes } from '../options';
import { i18nKeyListConvert } from '~/utilities/i18n-util';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select';
import CPSwim from '@/pages/_idIssuer/signatures/components/cp-swim';
import SigningUtils from './swim-components/signing-utils';

export default {
  name: 'CpCancelTransactionModal',
  components: {
    CPSwim,
    CpSelect,
    CpErrorNotices,
    Description,
    CpGeneralModal,
    CpInput,
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      working: false,
      signed: 0,
      errors: [],
      tList: [],
      privateKey: '',
      signerAddress: '',
      signatureTypes,
      selectedSignatureType: this.singleSignature,
      displayMultiSigWallets: false,
      selectedOptionToSign: 'withPrivateKey',
      deploymentId: null,
      multiSigWalletOptions: null,
      selectedMultiSigWallet: null,
      signatureCount: '',
      disableMultiSigWalletSelect: false,
      multipleMultiSigTransactionsSelected: false,
      multiSigWalletHasPendingSignatures: false,
      multiSigStatus: null,
      multiSigWallets: null,
      displayLastSignMessage: false,
      isLoading: true,
      clientTransactionId: null,
      multiSignature: 'multiSignature',
      singleSignature: 'singleSignature',
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    ...mapGetters('issuersInfo', ['issuerInfo']),
    getTransactionsIds() {
      const result = this.tList.map(el => el.id);
      return result.toString();
    },
    blockchainType() {
      if (/^algorand.*/i.test(this.deploymentToken.provider)) {
        return 'algorand';
      } if (this.deploymentToken.provider === 'aptos') {
        return 'aptos';
      }
        return 'ethereum';
    },
    getPrivateKeyInputValidation() {
      return this.isPrivateKeySignatureType
          ? 'required'
          : '';
    },
    isMultiSigTransactionWithPendingSignatures() {
      return !this.isPrivateKeySignatureType || this.multipleMultiSigTransactionsSelected || this.hasMultiSigWalletPendingSignatures;
    },
    isMultiSigWalletSelectDisabled() {
      return this.disableMultiSigWalletSelect || this.transactions.length > 1;
    },
    isMultiSigTransactionSelectedAndLoaded() {
      return !this.isLoading && this.multipleMultiSigTransactionsSelected;
    },
    isOkButtonDisable() {
      return this.isLoading || this.multipleMultiSigTransactionsSelected || this.hasMultiSigWalletPendingSignatures;
    },
    isPrivateKeySignatureType() {
      return (this.isPrivateKeySignatureTypeSingleSignature || this.isPrivateKeySignatureTypeMultiSignature);
    },
    isSingleTransactionSelected() {
      return this.transactions.length === 1;
    },
    hasMultiSigWalletPendingSignatures() {
      return (this.isPrivateKeySignatureTypeMultiSignature && this.multiSigWalletHasPendingSignatures);
    },
    getSignatureTypes() {
      return i18nKeyListConvert(this.signatureTypes, 'text');
    },
    isPrivateKeySignatureTypeSingleSignature() {
      return this.selectedOptionToSign === 'withPrivateKey' && this.selectedSignatureType === this.singleSignature;
    },
    isPrivateKeySignatureTypeMultiSignature() {
      return this.selectedOptionToSign === 'withPrivateKey' && this.selectedSignatureType === this.multiSignature && this.selectedMultiSigWallet !== null;
    },
    isSWIMOptionSelected() {
      return this.selectedOptionToSign === 'withSWIM';
    },
  },
  methods: {
    ...mapActions('signatures', [
      'sendTransactionSignature',
      'getMultisigWalletsByDeploymentId',
      'getMultisigWalletTransactionsStatus',
      'getTokenConfiguration',
      'prepareTransactionForCancel',
      'getTransactionSignatureById']),
    async init() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      this.isLoading = true;
      this.getTransactionsList();
      try {
        this.deploymentId = this.transactions[0].deploymentId;
        await this.getMultiSigSetup();
        await this.getTransactionSignatureDetail(issuerId, tokenId, this.transactions[0].id);
        this.isLoading = false;
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
      } finally {
        this.isLoading = false;
      }
    },
    async getMultiSigSetup() {
      this.multiSigWalletHasPendingSignatures = false;
      this.clientTransactionId = this.transactions[0].id;
      if (this.deploymentId) {
        await this.getMultisigWallets(this.deploymentId);
        this.multiSigStatus = await this.getMultiSigWalletStatus(this.deploymentId, this.clientTransactionId, true);
      }
      // Clean State!
      if (this.multiSigStatus) {
        this.multiSigWalletHasPendingSignatures = this.multiSigStatus.signingLock
            && this.multiSigStatus.signatures === 0
            && this.multiSigStatus.threshold >= 1;
      }
      this.displayMultiSigWallets = false;
      this.disableMultiSigWalletSelect = true;
      this.selectedSignatureType = this.signatureTypes[0].key;
      this.selectedMultiSigWallet = null;
      this.signatureCount = '';

      if (this.multiSigWallets && this.multiSigWallets.length > 0) {
        this.displayMultiSigWallets = false;
        this.disableMultiSigWalletSelect = false;
        this.selectedSignatureType = this.signatureTypes[0].key;
        if (this.multiSigStatus.multiSigWalletId) {
          this.displayMultiSigWallets = true;
          this.selectedSignatureType = this.signatureTypes[1].key;
          this.selectedMultiSigWallet = this.multiSigStatus.multiSigWalletId;
          this.disableMultiSigWalletSelect = true;
          this.signatureCount = `${this.multiSigStatus.signatures.toString()}/${this.multiSigStatus.threshold.toString()}`;
          this.displayLastSignMessage = this.multiSigStatus.status === 'unsigned'
              && (this.multiSigStatus.signatures + 1 === this.multiSigStatus.threshold);
        }
      }
    },
    async multiSigChanged() {
      try {
        this.isLoading = true;
        this.multiSigWalletHasPendingSignatures = false;
        this.multiSigStatus = await this.getMultiSigWalletStatus(this.deploymentId, this.clientTransactionId, true);
        if (this.multiSigStatus) {
          this.multiSigWalletHasPendingSignatures = this.multiSigStatus.signingLock;
        }
        if (this.multiSigStatus && this.multiSigStatus.transactionId !== '') {
          this.signatureCount = `${this.multiSigStatus.signatures.toString()}/${this.multiSigStatus.threshold.toString()}`;
        } else {
          const walletSelected = this.multiSigWallets.find(wallet => wallet.id === this.selectedMultiSigWallet);
          this.signatureCount = `0/${walletSelected.threshold.toString()}`;
          this.multiSigWalletHasPendingSignatures = walletSelected.signingLock;
        }
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    getMultiSigNonce() {
      if (this.multiSigStatus && this.multiSigStatus.transactionId !== '') {
        return this.multiSigStatus.nonce;
      }
      const walletSelected = this.multiSigWallets.find(wallet => wallet.id === this.selectedMultiSigWallet);
      return walletSelected.nonce;
    },
    getTransactionsList() {
      this.errors = [];
      this.privateKey = '';
      this.working = false;
      this.signerAddress = '';
      this.tList = this.transactions;
      const multiSigTransactionList = this.transactions.filter(transaction => transaction.threshold > 1);
      this.multipleMultiSigTransactionsSelected = this.transactions.length > 1 && multiSigTransactionList.length > 0;
    },
    async getMultisigWallets(deploymentId) {
      try {
        const result = await this.getMultisigWalletsByDeploymentId({ deploymentId });
        const walletDropDownList = result.data.map((wallet) => {
          const text = wallet.signingLock
              ? `${wallet.walletName} - ${this.$t('signatures.signTransactionModal.select.pendingSignatures.text')}`
              : wallet.walletName;
          return { value: wallet.id, text, signingLock: wallet.signingLock };
        });
        this.multiSigWalletOptions = walletDropDownList;
        this.multiSigWallets = result.data;
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
      }
    },
    async getMultiSigWalletStatus(deploymentId, clientTransactionId, includeTransactionData) {
      try {
        const result = await this.getMultisigWalletTransactionsStatus({
          deploymentId,
          clientTransactionId,
          includeTransactionData,
        });
        return result.data;
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
      }
    },
    async getTransactionSignatureDetail(issuerId, tokenId, id) {
      try {
        const transactionProviderDetail = await this.getTransactionSignatureById({
          id,
          issuerId,
          tokenId,
        });
        if (transactionProviderDetail.data) {
          this.signerAddress = transactionProviderDetail.data.signedAddress;
        }
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);
      }
    },
    optionToSignChanged() {
      this.signerAddress = '';
      this.privateKey = '';
      this.errors = [];
    },
    signatureTypeChanged() {
      this.displayMultiSigWallets = this.selectedSignatureType !== this.signatureTypes[0].key;
      this.errors = [];
    },
    async getAddressFromLedger() {
      try {
        return await BlockchainTransactionSigner.getLedgerAddress(this.blockchainType);
      } catch (error) {
        console.error(error);
        const signerError = this.handleBlockchainTransactionSignerError(error);
        this.errors.push(signerError || error.message || this.$t('signatures.signTransactionModal.message.ledgerConnectionError'));
        this.working = false;

        return null;
      }
    },
    async speedUpAll(isLedger) {
      this.errors = [];
      const { tList, privateKey, signerAddress } = this;
      this.signed = 0;
      this.working = true;
      this.$emit('handleOk', tList);

      const supportedLedgerBlockchains = ['ethereum', 'tezos'];
      const ledgerAddress = isLedger && supportedLedgerBlockchains.includes(this.blockchainType)
          ? await this.getAddressFromLedger()
          : null;

      if (isLedger && !ledgerAddress) {
        this.working = false;
        this.errors.push(this.$t('signatures.signTransactionModal.message.ledgerInvalidError'));
        return;
      }
      console.warn('isLedger', isLedger);
      console.warn('ledgerAddress', ledgerAddress);

      async.series(
          tList.map(({ id }) => (callback) => {
            this.cancelTransaction(isLedger
                    ? ledgerAddress
                    : signerAddress,
                id, privateKey, isLedger)
                .then(res => callback(null, res))
                .catch(err => callback(err, null))
                .finally(() => {
                  this.signed += 1;
                });
          }),
      )
          .then(() => {
            this.$emit('onSuccess');
            if (!this.errors.length) {
              this.$refs.modalLogic.hide();
            }
          })
          .catch((err) => {
            console.warn('error', err);
            this.errors.push(
                ...((err && err.response && err.response.body && err.response.body.message)
                    || err.message
                    || this.$t('signatures.signTransactionModal.message.commonError')).split(/,/g),
            );
          })
          .finally(() => {
            this.$emit('onSign');
            this.working = false;
          });
    },
    async cancelTransaction(signerAddress, blockchainTransactionId, privateKey, isLedger) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      let transactionData;
      let additionalData;
      try {
        const { data: responseData } = await this.prepareTransactionForCancel({
          issuerId,
          tokenId,
          blockchainTransactionId,
          signerAddress,
        });
        // eslint-disable-next-line prefer-destructuring
        transactionData = responseData.transactionData;
        additionalData = responseData.additionalData || {};
      } catch (error) {
        const errorText = this.getErrorText(error);
        if (errorText) this.errors.push(errorText);

        return;
      }

      const options = SigningUtils.getNetworkOptions(additionalData);
      const unsignedTransaction = typeof transactionData === 'string' ? transactionData || '{}' : JSON.stringify(transactionData || {});
      let signedTransaction = null;
      try {
        signedTransaction = isLedger
            ? await BlockchainTransactionSigner.signUsingLedger(this.blockchainType, unsignedTransaction, options)
            : await BlockchainTransactionSigner.sign(this.blockchainType, unsignedTransaction, privateKey, options);
      } catch (error) {
        if (!isLedger) {
          if (error.message === `BLOCKCHAIN_${this.blockchainType.toUpperCase()}_NOT_SUPPORTED`) {
            this.errors = [
              this.$t('signatures.signTransactionModal.message.blockchainTypeNotSupportedError', [this.blockchainType]),
            ];
          } else {
            this.errors = [this.$t('signatures.signTransactionModal.message.signFailure', [blockchainTransactionId])];
          }
        }

        return;
      }

      if (signedTransaction) {
        const body = { transactionOperation: 'cancel', transactionData: signedTransaction, identity: signerAddress };
        try {
          await this.sendTransactionSignature({ issuerId, tokenId, blockchainTransactionId, body });
        } catch (error) {
          const errorText = this.getErrorText(error);
          if (errorText) this.errors.push(errorText);
        }
      }
    },
    getErrorText(error) {
      let errorKey = error.response && error.response.data && error.response.data ? error.response.data.error : undefined;
      if (!errorKey) {
        errorKey = error.response && error.response.data && error.response.data ? error.response.data.name : undefined;
      }
      const errorsMap = {
        BLOCKCHAIN_ERROR: this.$t('signatures.signTransactionModal.message.blockchainError'),
        UNAUTHORIZED_WALLET: this.$t('signatures.signTransactionModal.message.unauthorizedWalletError'),
        NOT_ENOUGH_BALANCE: this.$t('signatures.signTransactionModal.message.notEnoughBalanceError'),
      };

      return errorsMap[errorKey];
    },
    submitTransactions() {
      this.speedUpAll(!this.isPrivateKeySignatureType);
    },

    handleBlockchainTransactionSignerError(error) {
      if (!error || !error.message) {
        return;
      }
      const CANNOT_DETECT_LEDGER_ERROR = 'Ledger device was detected but could not establish connection.';
      const COULD_NOT_ESTABLISH_CONNECTION_ERROR = 'Cannot detect a Ledger device. If it is connected, make sure it is unlocked.';

      const errorsMap = {
        [CANNOT_DETECT_LEDGER_ERROR]: this.$t('signatures.signTransactionModal.message.cannotDetectLedgerError'),
        [COULD_NOT_ESTABLISH_CONNECTION_ERROR]: this.$t('signatures.signTransactionModal.message.couldNotEstablishConnectionError'),
      };
      return errorsMap[error.message];
    },
    onSuccess() {
      this.$emit('onSuccess');
      this.$refs.modalLogic.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 0 !important;
}
</style>
