<template>
  <div>
    <cp-batch-actions
      :all-pending="allPending.length"
      :selected-pending="selectedTransactions.length"
      @onApplyFilter="applyFilter"
      @onCancelTransactionsModal="openOnCancelTransactionModal"
      @onOpenSpeedUpTransactionsModal="openOnSpeedUpTransactionModal"
      @onOpenTransactionsModal="openOnSignTransactionModal"
    />
    <cp-table
      ref="cpSignaturesTable"
      :filters="filters"
      @onCancelTransaction="openOnCancelTransactionModal"
      @onClearSignature="openClearSignaturesModal"
      @onDelete="openDeleteTransactionModal"
      @onSelect="onSelectTransaction"
      @onSign="openOnSignTransactionModal"
      @onSpeedUp="openOnSpeedUpTransactionModal"
      @onView="openViewTransactionModal"
    />
    <cp-view-modal
      ref="cpViewTransactionModal"
      :current-item="currentItem"
      :transaction-info="transactionInfo"
    />
    <cp-confirm-modal
      ref="cpConfirmModal"
      :item="currentItem"
      :title="this.$t('signatures.removeTransaction.title')"
      @onOk="submitDelete"
    >
      {{ deleteMessage }}
    </cp-confirm-modal>

    <cp-confirm-modal
      ref="cpConfirmClearSignaturesModal"
      :item="currentItem"
      :title="this.$t('signatures.clearSignatures.title')"
      @onOk="submitClearSignatures"
    >
      {{ clearSignaturesMessage }}
      <br><br><br>
      {{ clearSignaturesConfirmationMessage }}
    </cp-confirm-modal>

    <cp-sign-transaction-modal
      :key="modalKey"
      ref="cpSignTransactionModal"
      :transactions="selectedTransactions"
      @onSuccess="updateTable"
    />
    <cp-speed-up-transaction-modal
      ref="cpSpeedUpTransactionModal"
      :transactions="selectedTransactions"
      @onSuccess="updateTable"
    />
    <cp-cancel-transaction-modal
      ref="cpCancelTransactionModal"
      :transactions="selectedTransactions"
      @onSuccess="updateTable"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpBatchActions from './components/cp-batch-actions';
import fields from './fields';
import CpTable from './components/table';
import CpViewModal from './components/cp-view-modal';
import CpSignTransactionModal from './components/cp-sign-transaction-modal';
import CpSpeedUpTransactionModal from './components/cp-speed-up-transaction-modal';
import CpCancelTransactionModal from './components/cp-cancel-transaction-modal';

export default {
  name: 'Signatures',
  metaInfo: {
    title: 'Signatures',
  },
  components: {
    CpCancelTransactionModal,
    CpTable,
    CpViewModal,
    CpConfirmModal,
    CpSignTransactionModal,
    CpSpeedUpTransactionModal,
    CpBatchActions,
  },
  data() {
    return {
      statusFilter: 'pending',
      fields,
      currentItem: {},
      transactionInfo: {},
      deleteMessage: '',
      clearSignaturesMessage: '',
      clearSignaturesConfirmationMessage: '',
      selectedTransactions: [],
      allPending: [],
      filters: { status: 'pending' },
      modalKey: 0,
    };
  },
  watch: {
    async $route(newVal, oldVal) {
      if (newVal.params !== oldVal.params) {
        await this.getTokenDeploymentBasicInfo({ issuerId: newVal.params.idIssuer, tokenId: newVal.params.tokenId });
      }
    },
  },
  methods: {
    ...mapActions('configToken', ['getTokenDeploymentBasicInfo']),
    ...mapActions('signatures', ['deleteTransactionSignatureById', 'getTransactionSignatureById', 'clearTransactionSignaturesById']),
    openViewTransactionModal(item) {
      this.currentItem = item;
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      this.getTransactionSignatureById({ issuerId, tokenId, id: item.id }).then(({ data }) => {
        this.transactionInfo = data;
        this.$refs.cpViewTransactionModal.$refs.viewTransaction.$refs.confirmModal.show();
      });
    },
    openDeleteTransactionModal(item) {
      this.currentItem = item;
      this.deleteMessage = this.$t('signatures.removeTransaction.message.deleteCofrimation', [item.id]);
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    openClearSignaturesModal(item) {
      this.currentItem = item;
      this.clearSignaturesMessage = this.$t('signatures.clearSignatures.message.clearSignaturesMessage', [item.id]);
      this.clearSignaturesConfirmationMessage = this.$t('signatures.clearSignatures.message.clearSignaturesConfirmationMessage', [item.id]);
      this.$refs.cpConfirmClearSignaturesModal.$refs.confirmModal.show();
    },
    submitDelete({ id }) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      this.deleteTransactionSignatureById({ id, issuerId, tokenId }).then(() => {
        this.$refs.cpConfirmModal.$refs.confirmModal.hide();
        this.updateTable();
      });
    },
    submitClearSignatures({ id }) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      this.clearTransactionSignaturesById({ id, issuerId, tokenId }).then(() => {
        this.$refs.cpConfirmModal.$refs.confirmModal.hide();
        this.updateTable();
      });
    },
    openOnSignTransactionModal(item) {
      if (item) {
        this.currentItem = item;
      }
      this.selectedTransactions = this.getPendingTransactions();
      this.modalKey += 1;
      this.$nextTick(() => {
        this.$refs.cpSignTransactionModal.$refs.modalLogic.show();
      });
    },
    openOnSpeedUpTransactionModal(item) {
      if (item) {
        this.currentItem = item;
      }
      this.selectedTransactions = this.getSentTransactions();
      this.$refs.cpSpeedUpTransactionModal.$refs.modalLogic.show();
    },
    openOnCancelTransactionModal(item) {
      if (item) {
        this.currentItem = item;
      }
      this.selectedTransactions = this.getSentTransactions();
      this.$refs.cpCancelTransactionModal.$refs.modalLogic.show();
    },
    onSelectTransaction(item) {
      this.selectedTransactions = item;
      this.allPending = this.getPendingTransactions();
    },
    updateTable() {
      this.resetSelection();
      this.$refs.cpSignaturesTable.$refs.cpTable.updateTableData();
    },
    applyFilter(filters) {
      this.filters = { status: filters };
    },
    getPendingTransactions() {
      if (this.selectedTransactions.length) {
        return this.selectedTransactions;
      }
      const tableData = this.$refs.cpSignaturesTable.$refs.cpTable.getTableData();
      return tableData.filter(el => el.status === 'pending');
    },
    getSentTransactions() {
      if (this.selectedTransactions.length) {
        return this.selectedTransactions;
      }
      const tableData = this.$refs.cpSignaturesTable.$refs.cpTable.getTableData();
      return tableData.filter(el => (el.status === 'sent' || el.status === 'signed'));
    },
    resetSelection() {
      this.selectedTransactions = [];
    },
  },
};
</script>
